import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrOpeningHoursFragmentDoc } from 'fdr/schemas/fragment/opening-hours/fdr-opening-hours.generated';
import { FdrLinkFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-link.generated';
export type FdrFooterContactInformationFragment = {
  __typename?: 'FdrContactInformation';
  timezone: string;
  phoneNumber: string;
  mainTitle?: string | null;
  openingHours?: {
    __typename?: 'FdrOpeningHours';
    monday?: {
      __typename?: 'FdrWorkingDay';
      shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
    } | null;
    tuesday?: {
      __typename?: 'FdrWorkingDay';
      shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
    } | null;
    wednesday?: {
      __typename?: 'FdrWorkingDay';
      shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
    } | null;
    thursday?: {
      __typename?: 'FdrWorkingDay';
      shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
    } | null;
    friday?: {
      __typename?: 'FdrWorkingDay';
      shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
    } | null;
    saturday?: {
      __typename?: 'FdrWorkingDay';
      shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
    } | null;
    sunday?: {
      __typename?: 'FdrWorkingDay';
      shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
    } | null;
  } | null;
  link?: {
    __typename?: 'FdrLink';
    openMethod?: Types.FdrLinkOpenMethod | null;
    legacyUrl?: string | null;
    type: Types.FdrLinkType;
    url: string;
  } | null;
};

export const FdrFooterContactInformationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrFooterContactInformation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrContactInformation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'openingHours' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrOpeningHours' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrLink' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainTitle' } },
        ],
      },
    },
    ...FdrOpeningHoursFragmentDoc.definitions,
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
