import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import React, { useMemo } from 'react';
import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import { useFdrPersonalProfileQuery } from 'fdr/schemas/query/account/fdr-personal-profile.generated';
import { colors } from 'theme';

const Chat = dynamic(
  () =>
    import('@hotelplan/fdr.regular.fusion.fdr-chat').then(
      module => module.FdrChat
    ),
  { ssr: false }
);

const FdrChat: React.FC = () => {
  const { publicRuntimeConfig } = getConfig();
  const { channelType } = useAuthentication();

  const { data: fdrAccount } = useFdrPersonalProfileQuery({
    skip: channelType !== AuthChannelType.B2C,
  });
  const fdrCustomer =
    fdrAccount?.fdrAccount?.profile?.personalData?.accountCustomer;

  const personalChatData = useMemo(
    () => ({
      firstName: fdrCustomer?.firstName,
      lastName: fdrCustomer?.lastName,
      email: fdrCustomer?.email,
      phone: fdrCustomer?.phone?.phone,
    }),
    [fdrCustomer]
  );

  return (
    <Chat
      id={publicRuntimeConfig.chatId}
      personalData={personalChatData}
      systemConfig={{
        hb_primary_color: colors.lightGreySixth,
        hb_custom_style: {
          general: {
            corners: `hard`,
            secondaryColor: colors.primary,
          },
        },
      }}
    />
  );
};

export default FdrChat;
