import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrPersonalProfileQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type FdrPersonalProfileQuery = {
  __typename?: 'Query';
  fdrAccount?: {
    __typename?: 'FdrAccountPages';
    profile?: {
      __typename?: 'FdrAccountProfilePage';
      personalData: {
        __typename?: 'FdrAccountPersonalDataResponse';
        accountCustomer?: {
          __typename?: 'FdrAccountCustomer';
          salutation?: Types.FdrSalutation | null;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          birthdate?: any | null;
          nationality?: string | null;
          agencyInfo?: string | null;
          addressDetails?: {
            __typename?: 'FdrAccountAddressDetails';
            zip?: string | null;
            street?: string | null;
            city?: string | null;
            region?: string | null;
            country?: string | null;
          } | null;
          phone?: {
            __typename?: 'FdrAccountPhone';
            phone?: string | null;
            country?: string | null;
          } | null;
          mobilePhone?: {
            __typename?: 'FdrAccountPhone';
            country?: string | null;
            phone?: string | null;
          } | null;
        } | null;
      };
    } | null;
  } | null;
};

export const FdrPersonalProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrPersonalProfile' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'personalData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accountCustomer' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'salutation' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'addressDetails',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'zip' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'street',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'city' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'region',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'country',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'phone' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'phone',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'country',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mobilePhone',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'country',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'phone',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'birthdate' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'nationality',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'agencyInfo' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useFdrPersonalProfileQuery__
 *
 * To run a query within a React component, call `useFdrPersonalProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrPersonalProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrPersonalProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useFdrPersonalProfileQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FdrPersonalProfileQuery,
    FdrPersonalProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrPersonalProfileQuery,
    FdrPersonalProfileQueryVariables
  >(FdrPersonalProfileDocument, options);
}
export function useFdrPersonalProfileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrPersonalProfileQuery,
    FdrPersonalProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrPersonalProfileQuery,
    FdrPersonalProfileQueryVariables
  >(FdrPersonalProfileDocument, options);
}
export type FdrPersonalProfileQueryHookResult = ReturnType<
  typeof useFdrPersonalProfileQuery
>;
export type FdrPersonalProfileLazyQueryHookResult = ReturnType<
  typeof useFdrPersonalProfileLazyQuery
>;
export type FdrPersonalProfileQueryResult = Apollo.QueryResult<
  FdrPersonalProfileQuery,
  FdrPersonalProfileQueryVariables
>;
