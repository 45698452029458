import dynamic from 'next/dynamic';
import Head from 'next/head';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { LazyHydrate } from '@hotelplan/components.common.lazy-render';
import { FdrBoosters } from '@hotelplan/fdr.regular.fusion.fdr-boosters';
import { SharingUrlProvider } from '@hotelplan/fdr.regular.fusion.fdr-share-form';
import {
  DeviceType,
  getDeviceTypeByScreenWidth,
  useDeviceType,
} from '@hotelplan/libs.context.device-type';
import { useFeatureToggle } from '@hotelplan/libs.feature-toggle';
import { FdrBooster } from '@hotelplan/supergraph-api';
import { Container } from 'components/domain/container';
import SkipLinksComponent from 'components/domain/keyboard-navigation/SkipLinksComponent';
import Fonts from 'components/domain/layout/Fonts';
import { FeatureList } from 'config/pageConfig.constants';
import { FdrCustomerSupportBooster } from 'fdr/components/local/fdr-customer-support-booster/indes';
import { FdrFooter } from 'fdr/components/local/fdr-footer';
import { HelpOverlayToggleStateContextProvider } from 'fdr/components/local/fdr-help-overlay';
import { FdrHotelsVisitHistoryFragment } from 'fdr/schemas/query/history/fdr-hotels-visit-history.generated';
import { FdrCookieDisclaimerSection } from 'hp-mf/fdr/components/fdr-cookie-disclaimer-section';
import {
  FdrInformationNotificationSection,
  FdrMaintenanceNotificationsSection,
} from 'hp-mf/fdr/components/fdr-notifications';

const MetaModules = dynamic(
  () => import('components/domain/meta-modules/MetaModules'),
  {
    ssr: false,
  }
);

const FdrHeaderDesktop = dynamic<{}>(
  () => import('fdr/components/local/fdr-header/fdr-header-desktop')
);

const FdrHeaderMobile = dynamic<{}>(
  () => import('fdr/components/local/fdr-header/fdr-header-mobile')
);

const FdrLastSeenHotels = dynamic(() =>
  import('fdr/components/local/fdr-last-seen-hotels/fdr-last-seen-hotels').then(
    module => module.FdrLastSeenHotels
  )
);

const ContentWrapper = styled.div({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
});

const ContentChildrenContainer = styled.div({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
});

interface ILayoutProps {
  children?: React.ReactNode;
  disableFooter?: boolean;
  lastSeenHotels?: FdrHotelsVisitHistoryFragment[];
  boosters?: Array<FdrBooster>;
}

const Layout: React.FC<ILayoutProps> = props => {
  const { children, disableFooter, lastSeenHotels, boosters } = props;
  const { isEnabled } = useFeatureToggle();
  const { serverType, mobile } = useDeviceType();
  const [isTablet] = useState(
    () =>
      (typeof window !== 'undefined'
        ? getDeviceTypeByScreenWidth(window.screen.availWidth)
        : serverType) === DeviceType.Tablet
  );

  const isNotDesktop = serverType !== DeviceType.Desktop;
  const currentScale = isTablet ? '0.8' : '1';

  const header = useMemo(() => {
    const isMobileView = typeof window === 'undefined' ? isNotDesktop : mobile;

    return isMobileView ? <FdrHeaderMobile /> : <FdrHeaderDesktop />;
  }, [serverType, mobile]);

  return (
    <>
      <Head>
        <link rel="preconnect" href="https://tracking.bd4travel.com/" />
        <Fonts />
        <meta
          key="vp"
          name="viewport"
          content={`width=device-width, initial-scale=${currentScale}, viewport-fit=cover`}
        />
      </Head>
      <SharingUrlProvider>
        <HelpOverlayToggleStateContextProvider defaultValue={false}>
          <ContentWrapper data-id="content-wrapper">
            <SkipLinksComponent />
            <FdrInformationNotificationSection />
            <LazyHydrate whenIdle={true} noWrapper={true}>
              {header}
            </LazyHydrate>
            <ContentChildrenContainer
              id="maincontent"
              data-id="content-children"
            >
              {children}
            </ContentChildrenContainer>
            {lastSeenHotels?.length ? (
              <Container>
                <FdrLastSeenHotels lastSeenHotels={lastSeenHotels} />
              </Container>
            ) : null}
            {!disableFooter && <FdrFooter />}
          </ContentWrapper>
          <FdrCookieDisclaimerSection />
          <FdrMaintenanceNotificationsSection />
          {Array.isArray(boosters) && <FdrBoosters boosters={boosters} />}
          <LazyHydrate whenIdle={true} noWrapper={true}>
            <div>
              <MetaModules />
              {isEnabled(FeatureList.CUSTOMER_SUPPORT_BOOSTER) && (
                <FdrCustomerSupportBooster />
              )}
            </div>
          </LazyHydrate>
        </HelpOverlayToggleStateContextProvider>
      </SharingUrlProvider>
    </>
  );
};

export default Layout;
